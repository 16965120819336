import React from 'react'

const Select = ({ options, value, onChange }) => {
    return (
        <div>
            <select
                value={value}
                onChange={onChange}
                className="w-full p-2.5 bg-[#F9FBFF] border border-[#2F2F2F] rounded-full px-5 focus:outline-none focus:ring focus:ring-indigo-300"
            >
                <span className='text-[#7E7E7E] text-sm'>Short By</span>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default Select