import { FETCH_PRODUCTS_FAILURE, FETCH_PRODUCTS_REQUEST, FETCH_PRODUCTS_SUCCESS } from "../_constants/product.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";

export const fetchProducts = () => async (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
  
    try {
      const response = await APIcallFunction({
        method: 'GET',
        endPoint: '/admin/productList',
        header: headerForPrivateAPI()
      });
  
      dispatch({
        type: FETCH_PRODUCTS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCTS_FAILURE,
        payload: error.message
      });
    }
  };