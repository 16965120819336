import { DASHBOARD_COUNT_FAIL, DASHBOARD_COUNT_REQUEST, DASHBOARD_COUNT_SUCCESS } from "../_constants/dashboard.constants";



const initialState = {
    loading: false,
    data: {
      customer: 0,
      designer: 0,
      totalEarning: 0,
      grouth: 0
    },
    error: null
  };
  
  export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case DASHBOARD_COUNT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case DASHBOARD_COUNT_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: null
        };
      case DASHBOARD_COUNT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };