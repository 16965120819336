import { supportAndQuiriesConstant } from "../_constants";

export default function support(state = {}, action) {
    switch (action.type) {

        case supportAndQuiriesConstant.GET_TICKET_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case supportAndQuiriesConstant.GET_TICKET_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                ticketList: action?.user?.data
            };
        case supportAndQuiriesConstant.GET_TICKET_LIST_FAILURE:
            return {
                ...state,
                userUpdatePasswordByAdmin: action.user,
                loading: false,
            };


        case supportAndQuiriesConstant.GET_FAQ_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case supportAndQuiriesConstant.GET_FAQ_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                faqList: action?.user?.data?.list
            };
        case supportAndQuiriesConstant.GET_FAQ_LIST_FAILURE:
            return {
                ...state,
                userUpdatePasswordByAdmin: action.user,
                loading: false,
            };

        case supportAndQuiriesConstant.REPLY_TICKET_MSG_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case supportAndQuiriesConstant.REPLY_TICKET_MSG_SUCCESS:
            return {
                ...state,
                loading: true,
                ticketList: action?.user
            };
        case supportAndQuiriesConstant.REPLY_TICKET_MSG_FAILURE:
            return {
                ...state,
                userUpdatePasswordByAdmin: action.user,
                loading: false,
            };




        case supportAndQuiriesConstant.TICKET_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case supportAndQuiriesConstant.TICKET_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: true,
                ticketList: action?.user
            };
        case supportAndQuiriesConstant.TICKET_STATUS_UPDATE_FAILURE:
            return {
                ...state,
                userUpdatePasswordByAdmin: action.user,
                loading: false,
            };


        case supportAndQuiriesConstant.CREATE_FAQ_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case supportAndQuiriesConstant.CREATE_FAQ_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                ticketList: action?.user
            };
        case supportAndQuiriesConstant.CREATE_FAQ_LIST_FAILURE:
            return {
                ...state,
                userUpdatePasswordByAdmin: action.user,
                loading: false,
            };

        case supportAndQuiriesConstant.EDIT_FAQ_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case supportAndQuiriesConstant.EDIT_FAQ_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                ticketList: action?.user
            };
        case supportAndQuiriesConstant.EDIT_FAQ_LIST_FAILURE:
            return {
                ...state,
                userUpdatePasswordByAdmin: action.user,
                loading: false,
            };

        case supportAndQuiriesConstant.DELETE_FAQ_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case supportAndQuiriesConstant.DELETE_FAQ_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                ticketList: action?.user
            };
        case supportAndQuiriesConstant.DELETE_FAQ_LIST_FAILURE:
            return {
                ...state,
                userUpdatePasswordByAdmin: action.user,
                loading: false,
            };



        default:
            return state
    }
}