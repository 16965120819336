import React, { useRef, useEffect } from 'react';
import { Search, Filter, Info } from 'lucide-react';
import { PiDotsThreeCircleVerticalLight } from 'react-icons/pi';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import { FaAngleDown } from 'react-icons/fa';
import ViewFormPopup from './ViewModal';
import { DateRangePicker } from '../../components/callender/Callender';

const OrderTable = ({
    currentData,
    totalPages,
    currentPage,
    searchTerm,
    setSearchTerm,
    handlePageChange,
    isPopupOpen,
    setIsPopupOpen,
    dropdownOpen,
    toggleDropdown,
    activeTab,
    setActiveTab,
    dateRange,
    setDateRange
}) => {
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                toggleDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toggleDropdown]);

    const RefundsDisputesTable = () => (
        <div className="bg-gray-50">
            <div className="overflow-x-hidden w-full p-5">
                <table className="w-full">
                    <thead className="py-10">
                        <tr className="border-b bg-white font-extrabold text-[16px] text-black py-10">
                            <th className="px-6 py-3 text-left text-xs">Request ID</th>
                            <th className="px-6 py-3 text-left text-xs">Order ID</th>
                            <th className="px-6 py-3 text-left text-xs">Customer Name</th>
                            <th className="px-6 py-3 text-left text-xs">Request Type</th>
                            <th className="px-6 py-3 text-left text-xs">Request Date</th>
                            <th className="px-6 py-3 text-left text-xs">Status</th>
                            <th className="px-6 py-3 text-left text-xs">Action</th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-50">
                        {currentData.map((item, index) => (
                            <tr key={item._id} className="border-b hover:bg-gray-50">
                                <td className="px-6 py-4 text-sm">{item._id}</td>
                                <td className="px-6 py-4 text-sm">{item.userId?.name || 'N/A'}</td>
                                <td className="px-6 py-4 text-sm">{item.productId?.title || 'N/A'}</td>
                                <td className="px-6 py-4 text-sm">${item.price}</td>
                                <td className="px-6 py-4 text-sm">{new Date(item.createdAt).toLocaleDateString()}</td>
                                <td className="px-6 py-4">
                                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${item.status === 2 ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                                        }`}>
                                        {item.status === 2 ? 'Delivered' : 'Pending'}
                                    </span>
                                </td>
                                {/* <td className="px-6 py-4">
                                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${item.status === 'Delivered' ? 'bg-green-50 text-green-700' :
                                        item.status === 'Pending' ? 'bg-yellow-50 text-yellow-700' :
                                            'bg-red-50 text-red-700'
                                        }`}>
                                        {item.status}
                                    </span>
                                </td> */}
                                <td className="px-6 py-4">
                                    <button className="text-gray-400 hover:text-gray-600">
                                        <Info className="h-5 w-5" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    const OrderMonitoringTable = () => (
        <div className="bg-gray-50">
            <div className="overflow-x-hidden w-full p-5">
                <table className="w-full">
                    <thead className="py-10">
                        <tr className="border-b bg-white font-extrabold text-[16px] text-black py-10">
                            <th className="px-6 py-3 text-left text-xs">Order ID</th>
                            <th className="px-6 py-3 text-left text-xs">Customer Name</th>
                            <th className="px-6 py-3 text-left text-xs">Product</th>
                            <th className="px-6 py-3 text-left text-xs">Total Amount</th>
                            <th className="px-6 py-3 text-left text-xs">Order Date</th>
                            <th className="px-6 py-3 text-left text-xs">Status</th>
                            <th className="px-6 py-3 text-left text-xs">Action</th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-50">
                        {currentData.map((item, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50">
                                <td className="px-6 py-4 text-sm">{item._id}</td>
                                <td className="px-6 py-4 text-sm">{item.userId.name}</td>
                                <td className="px-6 py-4 text-sm">{item.productId.title}</td>
                                <td className="px-6 py-4 text-sm">${item.price}</td>
                                <td className="px-6 py-4 text-sm">
                                    {new Date(item.createdAt).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                                </td>
                                <td className="px-6 py-4">
                                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${item.status === 'Delivered' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                                        }`}>
                                        {item.status}
                                    </span>
                                </td>
                                <td className="px-6 py-4">
                                    <button className="text-gray-400 hover:text-gray-600" onClick={() => toggleDropdown(index)}>
                                        <PiDotsThreeCircleVerticalLight className="h-5 w-5" />
                                    </button>
                                    {dropdownOpen === index && (
                                        <div ref={dropdownRef} className="absolute right-10 mt-2 w-32 bg-white border rounded shadow">
                                            <ul className="flex flex-col">
                                                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Update</li>
                                                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                    <button onClick={() => setIsPopupOpen(true)}>View</button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <>
            {/* Top Navigation */}
            <div className="w-full bg-white border-b py-3 flex">
                <div className="flex items-center gap-1 px-4 w-1/2">
                    <div className="border rounded-3xl">
                        <button
                            className={`px-6 py-3 ${activeTab === 'Shopper' ? 'text-gray-100 bg-[#84315E] rounded-3xl border-purple-600 font-medium' : 'text-gray-500'
                                }`}
                            onClick={() => setActiveTab('Shopper')}
                        >
                            Order Monitoring
                        </button>
                        <button
                            className={`px-6 py-3 ${activeTab === 'Designer' ? 'text-gray-100 bg-[#84315E] rounded-3xl border-purple-600 font-medium' : 'text-gray-500'
                                }`}
                            onClick={() => setActiveTab('Designer')}
                        >
                            Refund & Disputes
                        </button>
                    </div>
                </div>

                <div className="p-4 bg-white w-1/2">
                    <div className="flex gap-2 items-center justify-end">
                        <div className="relative max-w-xs">
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search in Product"
                                className="pl-10 pr-4 py-2 w-full border rounded-3xl text-sm"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button className="px-4 py-2 border rounded-3xl flex items-center gap-2 text-sm bg-white">
                            <Filter className="h-4 w-4" />
                            Filter
                        </button>
                        {activeTab === 'Shopper' && (
                            <>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    onChange={setDateRange}
                                    className="py-4 rounded-3xl"
                                />
                                <div className="bg-[#84315E] rounded-3xl">
                                    <button className="text-white p-2 flex items-center gap-2 px-4">
                                        <HiOutlineDocumentAdd />
                                        Export Order
                                    </button>
                                </div>
                            </>
                        )}
                        {activeTab === 'Designer' && (
                            <div className="bg-white border rounded-3xl">
                                <button className="text-black p-2 flex items-center gap-2 px-4">
                                    Refund
                                    <FaAngleDown />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Render different tables based on active tab */}
            {activeTab === 'Shopper' ? <OrderMonitoringTable /> : <RefundsDisputesTable />}

            {/* Pagination */}
            <div className="flex justify-end gap-10 items-center p-4 bg-white border-t">
                <button
                    className={`text-sm cursor-pointer ${currentPage === 1 ? 'text-black' : 'text-gray-500'}`}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    Prev
                </button>
                <div className="flex gap-1">
                    {[...Array(totalPages)].map((_, i) => (
                        <button
                            key={i}
                            className={`px-3 py-1 text-sm rounded-md ${currentPage === i + 1 ? 'bg-[#84315E] text-white' : 'text-gray-500'
                                }`}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
                <button
                    className={`text-sm cursor-pointer ${currentPage === totalPages ? 'text-gray-800' : 'text-gray-500'}`}
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    Next
                </button>
            </div>

            {/* View Modal */}
            <ViewFormPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSave={(formData) => {
                    console.log('Saved:', formData);
                    setIsPopupOpen(false);
                }}
            />
        </>
    );
};

export default OrderTable;