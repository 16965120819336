import { 
  FETCH_COMMUNITY_MANAGE_REQUEST, 
  FETCH_COMMUNITY_MANAGE_SUCCESS, 
  FETCH_COMMUNITY_MANAGE_FAILURE,
  DELETE_COMMUNITY_REQUEST,
  DELETE_COMMUNITY_SUCCESS,
  DELETE_COMMUNITY_FAILURE,
  STATUS_UPDATE_COMMUNITY_REQUEST,
  STATUS_UPDATE_COMMUNITY_SUCCESS,
  STATUS_UPDATE_COMMUNITY_FAILURE
} from '../_constants/community.constants';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
import { alertActions } from './alert.actions';

export const fetchCommunityManage = () => async (dispatch) => {
  dispatch({ type: FETCH_COMMUNITY_MANAGE_REQUEST });

  try {
    const response = await APIcallFunction({
      method: 'GET',
      endPoint: '/community/getList',
      header: headerForPrivateAPI()
    });

    dispatch({
      type: FETCH_COMMUNITY_MANAGE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_COMMUNITY_MANAGE_FAILURE,
      payload: error.message
    });
  }
};

export const deleteCommunity = (communityId) => async (dispatch) => {
  dispatch({ type: DELETE_COMMUNITY_REQUEST });

  try {
    const response = await APIcallFunction({
      method: 'PATCH',
      endPoint: `/community/disableCommunity/${communityId}`,
      body: { communityId }, 
      header: headerForPrivateAPI()
    });

    dispatch({
      type: DELETE_COMMUNITY_SUCCESS,
      payload: {
        message: response.message,
        communityId
      }
    });

    // Refresh the community list after successful deletion
    dispatch(fetchCommunityManage());
    
    return response;
  } catch (error) {
    dispatch({
      type: DELETE_COMMUNITY_FAILURE,
      payload: error.message
    });
    throw error;
  }
};





export const updateStatusCommunity = (data) => async (dispatch) => {
  dispatch({ type: STATUS_UPDATE_COMMUNITY_REQUEST });



  try {
    const response = await APIcallFunction({
      method: 'PATCH',
      endPoint: `/community/disableCommunity`,
      body: data,
      header: headerForPrivateAPI()
    });

    if (response || response.data) {
      dispatch({
        type: STATUS_UPDATE_COMMUNITY_SUCCESS, // Corrected action type
        payload: response,
      });

      // Refresh the community list after successful update
      dispatch(alertActions.info('Status update Successfully'))
      dispatch(fetchCommunityManage());

      
      return response;
    }
  } catch (error) {
    dispatch({
      type: STATUS_UPDATE_COMMUNITY_FAILURE, // Corrected failure action type
      payload: error.message
    });
    throw error;
  }
};
