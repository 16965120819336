import React, { useState, useRef, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// import { Calendar } from 'lucide-react';
// import { LuArrowLeftRight } from 'react-icons/lu';
import { DateRangePicker } from '../../components/callender/Callender';

const CustomDropdown = ({ options, value, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="bg-white border border-gray-300 rounded-md px-4 py-2 w-40 text-left text-sm flex items-center justify-between"
            >
                <span>{value || placeholder}</span>
                <span className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}>
                    ▼
                </span>
            </button>
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                onChange(option.value);
                                setIsOpen(false);
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};


const TimeRangeSelector = ({ value, onChange }) => {
    const options = [
        { value: 'Day wise', label: 'Daily' },
        { value: 'Week Wise', label: 'Week wise' },
        { value: 'Month Wise', label: 'Month wise' },
        { value: 'Year Wise', label: 'Year wise' }
    ];

    return <CustomDropdown options={options} value={value} onChange={onChange} placeholder="Select Range" />;
};

export const RevenueChart = ({ data: initialData }) => {
    const [timeRange, setTimeRange] = useState('month');
    const [activeIndex, setActiveIndex] = useState(null);
    const [data, setData] = useState(initialData);

    useEffect(() => {
        // Here you would typically fetch or transform data based on the selected time range
        // This is a placeholder for demonstration
        const aggregateData = (range) => {
            switch (range) {
                case 'Day Wise':
                    return initialData.map(d => ({ ...d, revenue: d.revenue / 30 })); // Daily average
                case 'Week Wise':
                    return initialData.map(d => ({ ...d, revenue: d.revenue / 4 })); // Weekly average
                case 'Year Wise':
                    return initialData.map(d => ({ ...d, revenue: d.revenue * 12 })); // Yearly projection
                default:
                    return initialData;
            }
        };

        setData(aggregateData(timeRange));
    }, [timeRange, initialData]);

    return (
        <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-[24px] font-semibold">Total Revenue</h2>
                <TimeRangeSelector value={timeRange} onChange={setTimeRange} />
            </div>
            <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={data}
                        onMouseMove={(state) => {
                            if (state && state.activeTooltipIndex !== undefined) {
                                setActiveIndex(state.activeTooltipIndex);
                            }
                        }}
                        onMouseLeave={() => setActiveIndex(null)}
                    >
                        <XAxis
                            dataKey="month"
                            axisLine={true}
                            tickLine={true}
                        />
                        <YAxis
                            axisLine={true}
                            tickLine={true}
                            tickFormatter={(value) => `$${value / 1000}k`}
                        />
                        <Tooltip
                            formatter={(value) => [`$${value.toLocaleString()}`, 'Revenue']}
                            cursor={false}
                        />
                        <Bar
                            dataKey="revenue"
                            fill="#CCCCCC"
                            radius={[4, 4, 0, 0]}
                            maxBarSize={30}
                        >
                            {data.map((_, index) => (
                                <cell
                                    key={`cell-${index}`}
                                    fill={index === activeIndex ? '#84315E' : '#CCCCCC'}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export const SalesChart = ({ data: initialData }) => {
    const [selectedGender, setSelectedGender] = useState('all');
    const [activeIndex, setActiveIndex] = useState(null);
    const [dateRange, setDateRange] = useState({
        start: new Date(),
        end: new Date()
    });
    const [filteredData, setFilteredData] = useState(initialData);
    // const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const genderOptions = [
        { value: 'all', label: 'All' },
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'others', label: 'Others' }
    ];

    useEffect(() => {
        if (!Array.isArray(initialData)) {
            console.error('Invalid data format');
            setFilteredData([]);
            return;
        }

        let newData = [...initialData];

        if (selectedGender !== 'all') {
            newData = newData.filter(item => item.gender === selectedGender);
        }

        const startDate = dateRange.start.getTime();
        const endDate = dateRange.end.getTime();

        newData = newData.filter(item => {
            if (!item.date) return true;
            const itemDate = new Date(item.date).getTime();
            return itemDate >= startDate && itemDate <= endDate;
        });

        setFilteredData(newData);
    }, [dateRange, selectedGender, initialData]);

    return (
        <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-[24px] font-semibold">Total Sales</h2>
                <div className="flex gap-4">
                    <select
                        value={selectedGender}
                        onChange={(e) => setSelectedGender(e.target.value)}
                        className="px-3 py-1.5 border border-gray-200 rounded-md text-sm bg-white"
                    >
                        <option value="" disabled>Gender Wise</option>
                        {genderOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>

                    <DateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        // className="my-4" // Optional custom classes
                    />
                </div>
            </div>

            <div className="h-80">
                {filteredData.length === 0 ? (
                    <div className="h-full flex items-center justify-center text-gray-500">
                        No data available for the selected filters
                    </div>
                ) : (
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={filteredData}
                            onMouseMove={(state) => {
                                if (state && state.activeTooltipIndex !== undefined) {
                                    setActiveIndex(state.activeTooltipIndex);
                                }
                            }}
                            onMouseLeave={() => setActiveIndex(null)}
                        >
                            <XAxis
                                dataKey="month"
                                axisLine={true}
                                tickLine={true}
                            />
                            <YAxis
                                axisLine={true}
                                tickLine={true}
                                tickFormatter={(value) => `$${value / 1000}k`}
                            />
                            <Tooltip
                                formatter={(value) => [`$${value.toLocaleString()}`, 'Sales']}
                                cursor={false}
                            />
                            <Bar
                                dataKey="sales"
                                fill="#CCCCCC"
                                radius={[4, 4, 0, 0]}
                                maxBarSize={30}
                            >
                                {filteredData.map((_, index) => (
                                    <cell
                                        key={`cell-${index}`}
                                        fill={index === activeIndex ? '#84315E' : '#CCCCCC'}
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};
