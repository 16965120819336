import React, { useState, useEffect, useRef } from 'react';
import { CiCalendarDate } from 'react-icons/ci';
import { LuArrowLeftRight } from 'react-icons/lu';
// import { LuArrowLeftRight } from 'lucide-react';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const CustomCalendar = ({ date, onSelectDate }) => {
  const [currentDate, setCurrentDate] = useState(date || new Date());

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    const calendar = [];
    let day = 1;

    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          week.push(<td key={`empty-${j}`} className="p-2" />);
        } else if (day > daysInMonth) {
          week.push(<td key={`empty-end-${j}`} className="p-2" />);
        } else {
          const currentDay = day;
          const isSelected = 
            date &&
            date.getDate() === currentDay &&
            date.getMonth() === month &&
            date.getFullYear() === year;

          week.push(
            <td
              key={day}
              className={`p-2 text-center cursor-pointer hover:bg-gray-100 
                ${isSelected ? 'bg-blue-100' : ''}`}
              onClick={() => onSelectDate(new Date(year, month, currentDay))}
            >
              {day}
            </td>
          );
          day++;
        }
      }
      if (day > daysInMonth && i < 5) break;
      calendar.push(<tr key={i}>{week}</tr>);
    }
    return calendar;
  };

  return (
    <div className="bg-white border rounded-lg p-4 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handlePrevMonth}
          className="p-1 hover:bg-gray-100 rounded"
          aria-label="Previous month"
        >
          ←
        </button>
        <div className="font-semibold">
          {months[currentDate.getMonth()]} {currentDate.getFullYear()}
        </div>
        <button
          onClick={handleNextMonth}
          className="p-1 hover:bg-gray-100 rounded"
          aria-label="Next month"
        >
          →
        </button>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            {days.map(day => (
              <th key={day} className="p-2 text-sm font-medium text-gray-600">
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderCalendar()}</tbody>
      </table>
    </div>
  );
};

export const DateRangePicker = ({ dateRange, onChange, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectingEnd, setIsSelectingEnd] = useState(false);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsSelectingEnd(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDateSelect = (date) => {
    if (!isSelectingEnd) {
      onChange({ ...dateRange, start: date });
      setIsSelectingEnd(true);
    } else {
      if (date >= dateRange.start) {
        onChange({ ...dateRange, end: date });
        setIsOpen(false);
        setIsSelectingEnd(false);
      }
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <div className={`relative ${className}`} ref={pickerRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-3 py-1.5 text-sm border border-gray-200 rounded-md hover:border-gray-300"
        aria-label="Toggle date range picker"
      >
        <CiCalendarDate className='font-bold' />
        <h1>Date Range</h1>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 z-50">
          <div className="p-4 bg-white rounded-lg shadow-lg">
            <div className="text-sm mb-2 text-gray-600">
              {isSelectingEnd ? 'Select end date' : 'Select start date'}
            </div>
            <CustomCalendar
              date={isSelectingEnd ? dateRange.end : dateRange.start}
              onSelectDate={handleDateSelect}
            />
          </div>
        </div>
      )}
    </div>
  );
};