import { FETCH_ORDERS_FAILURE, FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS } from "../_constants/order.constants";

const initialState = {
    loading: false,
    orders: {
      data: {
        list: [],
        total: 0
      }
    },
    error: null
  };
  
  export default function orderReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_ORDERS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case FETCH_ORDERS_SUCCESS:
        return {
          ...state,
          orders: action.payload, // This will contain the entire response with data.list
          loading: false
        };
      case FETCH_ORDERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  }