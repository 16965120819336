import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Dashboard from "../../pages/dashboard/Dashboard";
import layoutJSON from './layout.json'
import UserManagement from "../../pages/userManagement/UserManagement";
import ChangePassword from "../../pages/changePassword/ChangePassword";
import UserLoginHistory from "../../pages/userLoginHistory,js/UserLoginHistory";
import ProductMangment from "../../pages/Products Managment/ProductMangment";
import OrderMangment from "../../pages/Order Mangment/OrderMangment";
import VarificationAndpropmation from "../../pages/Varification promation/VarificationAndpropmation";
import Advertisment from "../../pages/Advertisment/Advertisment";
import CommunityModeration from "../../pages/Community Moderation /CommunityModeration";
import Community from "../../pages/Community/Community";
import SupprtsAndQueries from "../../pages/Supports And Queries/SupprtsAndQueries";
import TransactionManagement from "../../pages/Transaction And Mangment/TransactionManagement";
import CommissionRevenueManagement from "../../pages/Commission & Revenue Management/CommissionRevenueManagement";
import CommunityContainer from "../../pages/Community/Community";
import SupportManagement from "../../pages/Supports And Queries/SupprtsAndQueries";

function Layout() {
  const location = useLocation()
  const [headerName, setHeaderName] = useState(null)
  const routesMap = {
    "/app/dashboard": "Dashboard",
    "/app/user": "User Management",
    "/app/product": "Product Management",
    "/app/order": "Order Management",
    "/app/verification&Promotion": "Verification & Promotion",
    "/app/communitymoderation": "Community Moderation",
    "/app/transaction&management": "Transaction Management",
    "/app/Support&Queries": "Support & Queries",
    "/app/commission&revenuemanagement": "Commission & Revenue ",
    "/app/community": "Community",
    "/app/advertisment": "Advertisment",
    "/app/security": "Change Password",
    "/app/loginHistory": "User Login History",
  };
  useEffect(() => {
    setHeaderName(routesMap[location.pathname] || "Unknown Module");
  }, [location]);
  return (
    <div>
      <div className="overflow-hidden bg-gray-100">
        <div className="min-h-screen flex flex-col justify-center">
          <div className="h-screen flex overflow-hidden">
            <Sidebar SidebarJSON={layoutJSON.sidebarJSON} />
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
              <Header HeaderJSON={layoutJSON.headerJSON} headerName={headerName} />
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/user" element={<UserManagement />} />
                <Route path="/product" element={<ProductMangment />} />
                <Route path="/order" element={<OrderMangment />} />
                <Route path="/verification&Promotion" element={<VarificationAndpropmation />} />
                <Route path="/communitymoderation" element={<CommunityModeration />} />
                <Route path="/transaction&management" element={<TransactionManagement />} />
                <Route path="/Support&Queries" element={<SupportManagement />} />
                <Route path="/commission&revenuemanagement" element={<CommissionRevenueManagement />} />
                <Route path="/community" element={<CommunityContainer />} />
                <Route path="/advertisment" element={<Advertisment />} />
                <Route path="/security" element={<ChangePassword />} />
                <Route path="/loginHistory" element={<UserLoginHistory />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
