import { alertConstants } from '../_constants'; // Raman
import { toast } from 'react-toastify';

export const alertActions = {
    success,
    error,
    test,
    clear,
    info
};

function success(message) {
    const messagetest = message ? message.toString() : ""; // Ensure message is defined
    toast(messagetest);
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    const messagetest = message ? message.toString() : ""; // Ensure message is defined
    toast(messagetest);
    return { type: alertConstants.ERROR, message: messagetest };
}

function info(message) {
    const messagetest = message ? message.toString() : ""; // Ensure message is defined
    toast(messagetest);
    return { type: alertConstants.ERROR, message: messagetest };
}


function clear() {
    return { type: alertConstants.CLEAR };
}

function test() {
    // Placeholder for test action
    return { type: alertConstants.CLEAR }; // Corrected return type
}
