import { FETCH_DESIGNER_FAILURE, FETCH_DESIGNER_REQUEST, FETCH_DESIGNER_SUCCESS } from "../_constants/designer.constants";

const initialState = {
  loading: false,
  designers: {
    data: {
      list: [],
      total: 0
    }
  },
  error: null
};

export default function designerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DESIGNER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_DESIGNER_SUCCESS:
      return {
        ...state,
        designers: action.payload, // This contains the entire response
        loading: false
      };
    case FETCH_DESIGNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}