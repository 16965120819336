import React, { useState } from 'react';
import { X } from 'lucide-react';

const ProductFormPopup = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    image: null,
    productName: '',
    designerName: '',
    price: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={onClose}>
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative z-50" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        
        <h2 className="text-2xl font-bold mb-6">Add New Product</h2>
        
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block mb-2">Upload Image</label>
              <button
                type="button"
                className="w-full px-4 py-2 bg-pink-100 text-pink-700 rounded hover:bg-pink-200"
              >
                Upload Image
              </button>
            </div>
            
            <div>
              <label className="block mb-2">Product Name</label>
              <input
                type="text"
                name="productName"
                value={formData.productName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Product Name"
              />
            </div>
            
            <div>
              <label className="block mb-2">Designer Name</label>
              <input
                type="text"
                name="designerName"
                value={formData.designerName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Designer Name"
              />
            </div>
            
            <div>
              <label className="block mb-2">Price</label>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Price"
              />
            </div>
          </div>
          
          <div className="mb-4">
            <label className="block mb-2">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-2 border rounded h-32"
              placeholder="Description"
            />
          </div>
          
          <button
            type="submit"
            className=" bg-[#84315E] text-white py-2 px-12 rounded-3xl hover:bg-purple-800"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProductFormPopup;