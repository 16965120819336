import { FETCH_TRANSACTION_FAILURE, FETCH_TRANSACTION_REQUEST, FETCH_TRANSACTION_SUCCESS } from "../_constants/transaction.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";

export const fetchTransaction = () => async (dispatch) => {
    dispatch({ type: FETCH_TRANSACTION_REQUEST });
  
    try {
      const response = await APIcallFunction({
        method: 'GET',
        endPoint: '/admin/transitionList',
        header: headerForPrivateAPI()
      });
  
      dispatch({
        type: FETCH_TRANSACTION_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_TRANSACTION_FAILURE,
        payload: error.message
      });
    }
  };