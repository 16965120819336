import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchTransaction } from '../actions/transaction.actions';
// import TransactionTable from './TransactionTable';
import { fetchTransaction } from '../../_actions/transaction.action';
import TransactionTable from './table';

const TransactionContainer = () => {
  const dispatch = useDispatch();
  const { transaction, loading, error } = useSelector((state) => state.transaction);

  useEffect(() => {
    dispatch(fetchTransaction());
  }, [dispatch]);

  // Transform API data to match table structure
  const transformedData = transaction?.list?.map(item => ({
    id: item._id,
    paymentType: item.paymentMethod === 1 ? 'Credit Card' : 'Other',
    userName: item.name,
    date: new Date(item.createdAt).toLocaleString(),
    status: item.status === 4 ? 'Not yet' : '3',
    amount: `$${item.amount.toFixed(2)}`
  })) || [];

  if (loading) {
    return <div className="w-full min-h-screen bg-white p-6">Loading...</div>;
  }

  if (error) {
    return <div className="w-full min-h-screen bg-white p-6">Error: {error}</div>;
  }

  return (
    <div className="w-full bg-gray-50 min-h-screen">
      <TransactionTable data={transformedData} />
    </div>
  );
};

export default TransactionContainer;