
import { FETCH_COMMISION_FAILURE, FETCH_COMMISION_REQUEST, FETCH_COMMISION_SUCCESS, EDIT_COMMISION_REQUEST, EDIT_COMMISION_SUCCESS, EDIT_COMMISION_FAILURE } from "../_constants/commision.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";
import { alertActions } from "./alert.actions";

export const fetchCommision = () => async (dispatch) => {
  dispatch({ type: FETCH_COMMISION_REQUEST });

  try {
    const response = await APIcallFunction({
      method: 'GET',
      endPoint: '/admin/getCommisionList',
      header: headerForPrivateAPI()
    });




    if (response.data) {
      dispatch({
        type: FETCH_COMMISION_SUCCESS,
        payload: response
      });
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Fetch commission error:', error);
    dispatch({
      type: FETCH_COMMISION_FAILURE,
      payload: error.message
    });
  }
};


export const editCommition = (data) => async (dispatch) => {

  dispatch({ type: EDIT_COMMISION_REQUEST });

  try {
    const response = await APIcallFunction({
      method: 'POST',
      endPoint: '/admin/giveCommision',
      header: headerForPrivateAPI(),
      body: data
    });

    if (response || response.data) {
      dispatch({
        type: EDIT_COMMISION_SUCCESS,
        payload: response,

      });
      dispatch(alertActions.success("Edit Success !"))
      await dispatch(fetchCommision());
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Fetch commission error:', error);
    dispatch({
      type: EDIT_COMMISION_FAILURE,
      payload: error.message
    });
  }
};