import React, { useEffect, useState } from 'react';
import Card from '../../components/ui/Card/Card.js';
import { Users,Paintbrush, TrendingUp } from 'lucide-react';
import { RevenueChart, SalesChart } from './DashboardChart.js';
import PopularItemsTable from './PopularItems.js'; // Import the PopularItemsTable component
import TopDesignerTable from './TopDesigner.js';
import { getDashboardCount } from '../../_actions/dashboard.actions.js';
import { useDispatch, useSelector } from 'react-redux';
import earning from '../../public/images/earning.png'

const Dashboard = () => {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardCount());
  }, [dispatch]);

  const metrics = [
    { 
      title: 'Total User', 
      amount: loading ? 'Loading...' : `${data.customer}`, 
      icon: Users 
    },
    { 
      title: 'Platforms Earning', 
      amount: loading ? 'Loading...' : `$${data.totalEarning}`, 
      icon: {earning} 
    },
    { 
      title: 'Designer Engagement', 
      amount: loading ? 'Loading...' : data.designer, 
      icon: Paintbrush 
    },
    { 
      title: 'User Growth', 
      amount: loading ? 'Loading...' : `${data.grouth}+`, 
      icon: TrendingUp 
    },
  ];
  const [chartData, setChartData] = useState([
    { month: 'Jan', revenue: 25000, sales: 28000 },
    { month: 'Feb', revenue: 30000, sales: 32000 },
    { month: 'Mar', revenue: 28000, sales: 29000 },
    { month: 'Apr', revenue: 35000, sales: 36000 },
    { month: 'May', revenue: 40000, sales: 42000 },
    { month: 'Jun', revenue: 45000, sales: 47000 },
    { month: 'Jul', revenue: 42000, sales: 43000 },
  ]);

  // Data for Popular Items Table
  const popularItemsData = [
    {
      image: 'https://via.placeholder.com/40',
      title: 'Summer Dress',
      interactionType: 'Purchases',
      engagementCount: '15%',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: 'Jane Doe (Designer)',
      interactionType: 'Favorites',
      engagementCount: '15%',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: 'Casual Shirt Collection',
      interactionType: 'Views',
      engagementCount: '15%',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: '3D Character Modelling',
      interactionType: 'Purchases',
      engagementCount: '15%',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: 'Webapp Design System',
      interactionType: 'Purchases',
      engagementCount: '15%',
    },
  ];
  const TopDesignerData = [
    {
      image: 'https://via.placeholder.com/40',
      title: 'Summer Dress',
      rating: 'Purchases',
      earning: '250$',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: 'Jane Doe (Designer)',
      rating: 'Favorites',
      earning: '250$',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: 'Casual Shirt Collection',
      rating: 'Views',
      earning: '250$',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: '3D Character Modelling',
      rating: 'Purchases',
      earning: '250$',
    },
    {
      image: 'https://via.placeholder.com/40',
      title: 'Webapp Design System',
      rating: 'Purchases',
      earning: '250$',
    },
  ];

  if (error) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
      <div className="relative">
        <div className="relative z-20 flex min-h-screen">
          <div className="flex w-full overflow-hidden">
            <div className="flex flex-col flex-1 overflow-hidden">
              <main className="relative flex-1">
                <div className="sm:p-5">
                  <div className="mx-auto max-w-screen-3xl">
                    {/* Metrics Section */}
                    <section className="my-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                      {metrics.map((metric, index) => (
                          <Card
                            key={index}
                            title={metric.title}
                            Aamount={metric.amount}
                            Icon={metric.icon}
                          />
                        ))}
                      </div>
                    </section>

                    {/* Charts Section */}
                    <section>
                      <div className="grid md:grid-cols-2 gap-8">
                        <RevenueChart data={chartData} />
                        <SalesChart data={chartData} />
                      </div>
                    </section>

                    {/* Popular Items Section */}
                    <section className='py-10'>
                      {/* <h2 className="text-xl font-bold mb-4">Popular Items</h2> */}
                      <div className="grid md:grid-cols-2 gap-8">
                      <PopularItemsTable items={popularItemsData} />
                      <TopDesignerTable items={TopDesignerData} />
                      </div>
                    </section>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
