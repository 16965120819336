import React from 'react';

const PopularItemsTable = ({ items }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center pb-4 border-b bg-white">
        <h2 className="text-xl font-bold">Popular Items Table</h2>
        <button className="bg-gray-50 text-black px-4 py-2 rounded-xl border border-black-500">
          Purchases 
        </button>
      </div>
      <table className="w-full mt-4 border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-3 text-left text-gray-600">Items Name</th>
            <th className="p-3 text-left text-gray-600">Interaction Type</th>
            <th className="p-3 text-left text-gray-600">Engagement Count</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index} className="border-b bg-white hover:bg-gray-50">
              <td className="p-3 flex items-center gap-3">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-10 h-10 rounded object-cover"
                />
                <span className="font-semibold">{item.title}</span>
              </td>
              <td className="p-3">{item.interactionType}</td>
              <td className="p-3">{item.engagementCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PopularItemsTable;
