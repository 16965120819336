import React from 'react'
import InputField from '../../../components/ui/InputFeilds/InputFeilds'
import { IoCloseOutline } from 'react-icons/io5'
import TextAreaField from '../../../components/ui/TextArea/Textarea'

const ViewModal = ({ title, inputValue, onCancel, handleOnChange, onSubmit }) => {
    console.log(inputValue)
    return (
        <div>
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-7xl overflow-hidden overflow-y-auto">
                    <div className='w-full flex justify-between items-center p-4 border-b'>
                        <p>{title}</p>
                        <button onClick={onCancel} className='w-8 h-8 rounded-full bg-[#F2F2F7] flex justify-center items-center'>
                            <IoCloseOutline />

                        </button>
                    </div>

                    <div className='p-4 capitalize'>
                        <p className='pb-3'><strong>Ticket : </strong>{inputValue?.userId?.name}</p>
                        <p><strong>{inputValue?.title} </strong></p>
                        <p className='py-2 text-gray-500 text-sm'>{inputValue?.desc} </p>


                    </div>


                    <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <InputField label={`Uploaded By`} placeholder={``} value={inputValue?.userId?.email} />
                        </div>
                        <div>
                            <InputField label={`Content Type`} placeholder={``} value={inputValue?.status === 1 ? ' Deposit issue' : 'Withdraw issue'} />
                        </div>
                    </div>


                    <div className="p-4">
                        <TextAreaField label={`Comments`} placeholder={`Write here`} value={inputValue?.comments} name={`comments`} onChange={handleOnChange} />
                    </div>

                    <div className="flex justify-end space-x-3 mt-6 border-t p-4">
                        <button
                            className="px-6 py-2 border border-[#B8B8D2] text-gray-700 rounded hover:bg-gray-300"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                            onClick={onSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewModal