import React, { useState, useMemo, useEffect } from 'react';
import OrderTable from './OrderTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../_actions/order.action';

const OrderManagement = () => {
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector(state => state.orders);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('Shopper');
  const [statusFilter, setStatusFilter] = useState('all');
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date()
  });
  const itemsPerPage = 7;

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const filteredData = useMemo(() => {
    if (!orders?.list) return [];
    return orders.list.filter((item) =>
      [
        item.addressId,
        item.productId?.title,
        item.userId?.name,
        item.price?.toString(),
        item.status?.toString()
      ].some(value =>
        value?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [orders, searchTerm]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(prevIndex => prevIndex === index ? null : index);
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full bg-gray-50 min-h-screen">

      {orders?.list && (
        <OrderTable
          currentData={currentData}
          totalPages={totalPages}
          currentPage={currentPage}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handlePageChange={handlePageChange}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          dropdownOpen={dropdownOpen}
          toggleDropdown={toggleDropdown}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      )}

    </div>
  );
};

export default OrderManagement;