import { FETCH_COMMUNITY_FAILURE, FETCH_COMMUNITY_REQUEST, FETCH_COMMUNITY_SUCCESS } from "../_constants/community.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";

export const fetchCommunity = () => async (dispatch) => {
    dispatch({ type: FETCH_COMMUNITY_REQUEST });
  
    try {
      const response = await APIcallFunction({
        method: 'GET',
        endPoint: '/post/list',
        header: headerForPrivateAPI()
      });
  
      dispatch({
        type: FETCH_COMMUNITY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_COMMUNITY_FAILURE,
        payload: error.message
      });
    }
  };



  