import React from 'react';

const Button = ({ label, onClick, className, leftIcon, rightIcon }) => {
  const defaultClass = 'px-4 py-2 rounded-full font-medium transition flex justify-center  text-gray-600'
  return (
    <div>

      <button
        onClick={onClick}
        className={className ? `${defaultClass} ${className}` : defaultClass}
      >
        {leftIcon && <span>{leftIcon}</span>}
        <span>{label}</span>
        {rightIcon && <span>{rightIcon}</span>}
      </button>
    </div>
  );
};

export default Button;
