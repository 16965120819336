import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VerificationTable from './VerificationTable';
import { fetchDesigners } from '../../_actions/designerList.action';

const VerificationTableContainer = () => {
  const dispatch = useDispatch();
  const { designers, loading, error } = useSelector(state => state.designers); // Changed from state.orders
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  useEffect(() => {
    dispatch(fetchDesigners());
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(prevIndex => prevIndex === index ? null : index);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="w-full bg-gray-50 min-h-screen">
      {designers?.list && (
        <VerificationTable 
          data={designers.list}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          dropdownOpen={dropdownOpen}
          toggleDropdown={toggleDropdown}
        />
      )}
    </div>
  );
};

export default VerificationTableContainer;