import React, { useState, useMemo } from 'react';
import { Search, Info } from 'lucide-react';
import { CiFilter } from "react-icons/ci";

const TransactionTable = ({ data: initialData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const filteredData = useMemo(() => {
    return initialData.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [initialData, searchTerm]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="w-full min-h-screen bg-white p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-semibold">Transaction History</h1>
        <div className="flex gap-3">
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
            <input
              type="text"
              placeholder="Search in transactions"
              className="pl-10 pr-4 py-2 border rounded-lg text-sm w-60"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="px-4 py-2 border rounded-lg flex items-center gap-2 text-sm">
            <CiFilter className="h-4 w-4" />
            Filter
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="w-full overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-50 text-sm text-gray-600">
              <th className="py-4 px-6 text-left font-medium">#</th>
              <th className="py-4 px-6 text-left font-medium">Transaction ID</th>
              <th className="py-4 px-6 text-left font-medium">Payment Type</th>
              <th className="py-4 px-6 text-left font-medium">User Name</th>
              <th className="py-4 px-6 text-left font-medium">Date & Time</th>
              <th className="py-4 px-6 text-left font-medium">Status</th>
              <th className="py-4 px-6 text-left font-medium">Amount</th>
              <th className="py-4 px-6 text-left font-medium">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={item.id} className="border-b hover:bg-gray-50">
                <td className="py-4 px-6">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td className="py-4 px-6">{item.id}</td>
                <td className="py-4 px-6">{item.paymentType}</td>
                <td className="py-4 px-6">{item.userName}</td>
                <td className="py-4 px-6">{item.date}</td>
                <td className="py-4 px-6">
                  <span className={`px-3 py-1 rounded-full text-xs ${
                    item.status === 'Not yet' 
                      ? 'bg-green-100 text-green-600'
                      : 'bg-red-100 text-red-600'
                  }`}>
                    {item.status === 'Not yet' ? 'Successful' : 'Failed'}
                  </span>
                </td>
                <td className="py-4 px-6">{item.amount}</td>
                <td className="py-4 px-6">
                  <button className="text-gray-400 hover:text-gray-600">
                    <Info className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-end items-center gap-2 mt-4 p-4">
        <button
          className="px-3 py-1 text-sm text-gray-600"
          disabled={currentPage === 1}
          onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
        >
          Prev
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            className={`px-3 py-1 rounded-md text-sm ${
              currentPage === page
                ? 'bg-[#84315E] text-white'
                : 'text-gray-600'
            }`}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </button>
        ))}
        {totalPages > 1 && <span className="px-2 text-gray-600">...</span>}
        <button
          className="px-3 py-1 text-sm text-gray-600"
          disabled={currentPage === totalPages}
          onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TransactionTable;