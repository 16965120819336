import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import alertReducer from './alert.reducer';
import authReducer from './security.reducer';
import loginHistoryReducer from './loginHistory.reducer';
import productReducer from './product.reducer';
import orderReducer from './order.reducer';
import designerReducer from './designer.reducer';
import communityReducer from './community.moderation.reducer.';
import transactionReducer from './transaction.reducer';
import communityManageReducer from './community.manage.reducer';
import support from './support.reducer'
import { dashboardReducer } from './dashboard.constants';
import commisionReducer from './commision.reducer';

const rootReducer = combineReducers({
    users: usersReducer,
    alert: alertReducer,
    auth: authReducer,
    loginHistory: loginHistoryReducer,
    products: productReducer,
    orders: orderReducer,
    designers: designerReducer,
    community: communityReducer,
    transaction: transactionReducer,
    communityM: communityManageReducer,
    support: support,
    // communityM: communityManageReducer, 
    dashboard:dashboardReducer,
    commision: commisionReducer,

});

export default rootReducer;