import { FETCH_TRANSACTION_FAILURE, FETCH_TRANSACTION_REQUEST, FETCH_TRANSACTION_SUCCESS } from "../_constants/transaction.constants";

const initialState = {
  loading: false,
  transaction: {
    data: {
      list: [],
      total: 0
    }
  },
  error: null
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.payload, // Changed from designers to TRANSACTION
        loading: false
      };
    case FETCH_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}