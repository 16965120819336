import { FETCH_DESIGNER_FAILURE, FETCH_DESIGNER_REQUEST, FETCH_DESIGNER_SUCCESS } from "../_constants/designer.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";

export const fetchDesigners = () => async (dispatch) => {
    dispatch({ type: FETCH_DESIGNER_REQUEST });
  
    try {
      const response = await APIcallFunction({
        method: 'GET',
        endPoint: '/admin/designerReqList',
        header: headerForPrivateAPI()
      });
      
      dispatch({
        type: FETCH_DESIGNER_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_DESIGNER_FAILURE,
        payload: error.message
      });
    }
  };