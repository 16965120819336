import React, { useState, useMemo, useEffect } from 'react';
import { Search } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import ProductTable from './ProductTable';
import { fetchProducts } from '../../_actions/product.actions';
import ProductFormPopup from './AddProductModal';
import DeleteConfirmationModal from '../../components/ui/DeleteModal';
// import DeleteConfirmationModal from './DeleteModal';

const ProductTableContainer = () => {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector(state => state.products);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const itemsPerPage = 7;

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const filteredData = useMemo(() => {
    if (!products) return [];
    return products.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [products, searchTerm]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onSave = () => {}

  const toggleDropdown = (index) => {
    setDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  if (loading) {
    return <div className="w-full text-center py-4">Loading...</div>;
  }

  if (error) {
    return <div className="w-full text-center py-4 text-red-500">{error}</div>;
  }

  return (
    <div className="w-full bg-gray-50 min-h-screen">
      <ProductTable 
        currentData={currentData}
        totalPages={totalPages}
        currentPage={currentPage}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handlePageChange={handlePageChange}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        dropdownOpen={dropdownOpen}
        toggleDropdown={toggleDropdown}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
      />
      
      <ProductFormPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onSave={onSave}
      />
      
      <DeleteConfirmationModal
        isOpen={isDelete}
        onClose={() => setIsDelete(false)}
        itemName="product list"
      />
    </div>
  );
};

export default ProductTableContainer;