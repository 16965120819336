import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions/user.actions';
import UsersTable from './UserTable';

const UsersTableContainer = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state)
  // const userList = useSelector(state => state.users?.userList || { total: 0, list: [] });
  // const loading = useSelector(state => state.users?.loading);
  const { users } = selector ? selector : {}
  const { userList: { list = [], total = null } } = users ? users : {}
  console.log('userDarsaaa', list)

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  useEffect(() => {
    dispatch(userActions.fetchUserList());
  }, [dispatch]);

  const transformedData = list?.map(user => ({
    id: user._id,
    name: user.name,
    email: user.email,
    role: user.role === 3 ? 'Designer' : 'Shopper',
    type: user.role === 3 ? 'Designer' : 'Shopper',
    status: user.isActive ? 'Active' : 'Inactive',
    lastLogin: user.lastLogin ? new Date(user.lastLogin).toLocaleDateString() : 'Never',
    purchaseHistory: user.purchaseHistory || '0'
  })) || [];


  console.log('transform', transformedData);

  return (
    <div className="w-full bg-gray-50 min-h-screen">
      <UsersTable
        data={transformedData}
        totalRecords={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
      />

    </div>
  );
};

export default UsersTableContainer;