import { FETCH_ORDERS_FAILURE, FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS } from "../_constants/order.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";

export const fetchOrders = () => async (dispatch) => {
    dispatch({ type: FETCH_ORDERS_REQUEST });
  
    try {
      const response = await APIcallFunction({
        method: 'GET',
        endPoint: '/admin/orderList',
        header: headerForPrivateAPI()
      });
  
      dispatch({
        type: FETCH_ORDERS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_ORDERS_FAILURE,
        payload: error.message
      });
    }
  };