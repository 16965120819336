import {
  FETCH_COMMUNITY_MANAGE_REQUEST,
  FETCH_COMMUNITY_MANAGE_SUCCESS,
  FETCH_COMMUNITY_MANAGE_FAILURE,
  DELETE_COMMUNITY_REQUEST,
  DELETE_COMMUNITY_SUCCESS,
  DELETE_COMMUNITY_FAILURE,

} from '../_constants/community.constants';

const initialState = {
  loading: false,
  community: {
    data: {
      list: [],
      total: 0
    }
  },
  error: null,
  deleteLoading: false,  
  deleteError: null      
};

export default function communityManageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMUNITY_MANAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_COMMUNITY_MANAGE_SUCCESS:
      return {
        ...state,
        community: action.payload,
        loading: false
      };
    case FETCH_COMMUNITY_MANAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      case DELETE_COMMUNITY_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: null
        };
        case DELETE_COMMUNITY_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            community: {
              ...state.community,
              data: {
                ...state.community.data,
                list: state.community.data.list.filter(
                  item => item._id !== action.payload.communityId
                )
              }
            }
          };
      case DELETE_COMMUNITY_FAILURE:
        return {
          ...state,
          community: action.payload, 
          loading: false
        };
    default:
      return state;
  }
}