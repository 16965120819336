import { supportAndQuiriesConstant } from '../_constants';
import { alertActions } from './alert.actions';
import {
    APIcallFunction,
    headerForPrivateAPI,
} from "../_helpers";

export const supportAction = {

    getTicketList, replyMessageOnTicket, ticketStatusUpdate, getFaqList, createFaq, editFaq, deleteFaq
};

function getTicketList(data) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "GET",
        endPoint: `/support/getTicketList`,
        body: data || {}
    }

    console.log('credentialscredentialscredentials', credentials)
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: supportAndQuiriesConstant.GET_TICKET_LIST_REQUEST, user } }
    function success(user) { return { type: supportAndQuiriesConstant.GET_TICKET_LIST_SUCCESS, user } }
    function failure(error) { return { type: supportAndQuiriesConstant.GET_TICKET_LIST_FAILURE, error } }
}




function getFaqList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "GET",
        endPoint: `/support/getFaqList`,
        body: data
    }

    console.log('credentialscredentialscredentials', credentials)
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: supportAndQuiriesConstant.GET_FAQ_LIST_REQUEST, user } }
    function success(user) { return { type: supportAndQuiriesConstant.GET_FAQ_LIST_SUCCESS, user } }
    function failure(error) { return { type: supportAndQuiriesConstant.GET_FAQ_LIST_FAILURE, error } }
}


function replyMessageOnTicket(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        endPoint: `/support/replyMessageOnTicket`,
        body: data
    }

    console.log('credentialscredentialscredentials', credentials)
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));
                dispatch(getTicketList())
                dispatch(alertActions.success("Your msg send successfully"))

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: supportAndQuiriesConstant.REPLY_TICKET_MSG_REQUEST, user } }
    function success(user) { return { type: supportAndQuiriesConstant.REPLY_TICKET_MSG_SUCCESS, user } }
    function failure(error) { return { type: supportAndQuiriesConstant.REPLY_TICKET_MSG_FAILURE, error } }
}







function ticketStatusUpdate(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        endPoint: `/support/ticketStatusUpdate`,
        body: data
    }

    console.log('credentialscredentialscredentials', credentials)
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));
                dispatch(getTicketList())
                dispatch(alertActions.success("Status update successfully"))

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: supportAndQuiriesConstant.TICKET_STATUS_UPDATE_REQUEST, user } }
    function success(user) { return { type: supportAndQuiriesConstant.TICKET_STATUS_UPDATE_SUCCESS, user } }
    function failure(error) { return { type: supportAndQuiriesConstant.TICKET_STATUS_UPDATE_FAILURE, error } }
}


function createFaq(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        endPoint: `/support/saveFaq`,
        body: data
    }

    console.log('credentialscredentialscredentials', credentials)
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));
                dispatch(getFaqList())
                dispatch(alertActions.success("Faq Create Success"))

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: supportAndQuiriesConstant.CREATE_FAQ_LIST_REQUEST, user } }
    function success(user) { return { type: supportAndQuiriesConstant.CREATE_FAQ_LIST_SUCCESS, user } }
    function failure(error) { return { type: supportAndQuiriesConstant.CREATE_FAQ_LIST_FAILURE, error } }
}


function editFaq(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        endPoint: `/support/saveFaq`,
        body: data
    }

    console.log('credentialscredentialscredentials', credentials)
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));
                dispatch(getFaqList())
                dispatch(alertActions.success("Edit successfully"))

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: supportAndQuiriesConstant.CREATE_FAQ_LIST_REQUEST, user } }
    function success(user) { return { type: supportAndQuiriesConstant.CREATE_FAQ_LIST_SUCCESS, user } }
    function failure(error) { return { type: supportAndQuiriesConstant.CREATE_FAQ_LIST_FAILURE, error } }
}



function deleteFaq(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "DELETE",
        endPoint: `/support/deleteFaq`,
        body: data
    }

    console.log('credentialscredentialscredentials', credentials)
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));
                dispatch(getFaqList())
                dispatch(alertActions.success("Your msg send successfully"))

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: supportAndQuiriesConstant.DELETE_FAQ_LIST_REQUEST, user } }
    function success(user) { return { type: supportAndQuiriesConstant.DELETE_FAQ_LIST_SUCCESS, user } }
    function failure(error) { return { type: supportAndQuiriesConstant.DELETE_FAQ_LIST_FAILURE, error } }
}













