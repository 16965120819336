import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Info, Search } from 'lucide-react';
import { PiDotsThreeCircleVerticalLight } from 'react-icons/pi';
import DeleteConfirmationModal from '../../components/ui/DeleteModal';
import profile from "../../public/images/profile.png"
import DesignerDetails from './Details';

const VerificationTable = ({
    data,
    searchTerm,
    setSearchTerm,
    currentPage,
    handlePageChange,
    isPopupOpen,
    setIsPopupOpen,
    dropdownOpen,
    toggleDropdown
}) => {
    const [isDelete, setIsDelete] = useState(false);
    const [selectedDesigner, setSelectedDesigner] = useState(null);
const [showDetails, setShowDetails] = useState(false);
    const itemsPerPage = 7;
    const dropdownRef = useRef(null);

    // Filter data based on search
    const filteredData = useMemo(() => {
        if (!data) return [];
        return data.filter((item) =>
            Object.values(item).some((value) =>
                value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [data, searchTerm]);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const currentData = useMemo(() => {
        return filteredData.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
    }, [filteredData, currentPage]);

    // Format date function
    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        return new Date(timestamp).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                toggleDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toggleDropdown]);

    // Get status badge
    const getStatusBadge = (status) => {
        const statusMap = {
            1: { text: 'Pending', class: 'bg-yellow-100 text-yellow-800' },
            2: { text: 'Approved', class: 'bg-green-100 text-green-800' },
            3: { text: 'Rejected', class: 'bg-red-100 text-red-800' }
        };
        const statusInfo = statusMap[status] || { text: 'Unknown', class: 'bg-gray-100 text-gray-800' };

        return (
            <span className={`px-2 py-1 rounded-full text-xs ${statusInfo.class}`}>
                {statusInfo.text}
            </span>
        );
    };

    return (
        <div className="w-full bg-gray-50 min-h-screen">
            {/* Top Navigation */}
            <div className="w-full bg-white border-b py-3 flex">
                <div className="flex items-center gap-1 px-4 w-1/2">
                    <h1 className='text-[24px]'>Designer Verification & Promotion</h1>
                </div>

                <div className="p-4 bg-white w-1/2">
                    <div className="flex gap-2 items-center justify-end">
                        <div className="relative max-w-xs">
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search designers..."
                                className="pl-10 pr-4 py-2 w-full border rounded-2xl text-sm"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Table */}
         
                <div className="overflow-x-auto w-full p-5 h-screen">
                    <table className="w-full">
                        <thead className='py-10'>
                            <tr className="border-b bg-white font-extrabold text-[16px] text-black py-10">
                                <th className="px-6 py-3 text-left text-xs">Profile Picture</th>
                                <th className="px-6 py-3 text-left text-xs">Designer Name</th>
                                <th className="px-6 py-3 text-left text-xs">Portfolio Link</th>
                                <th className="px-6 py-3 text-left text-xs">Submit Date</th>
                                <th className="px-6 py-3 text-left text-xs">Status</th>
                                <th className="px-6 py-3 text-left text-xs">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-gray-50">
                            {currentData.map((item, index) => (
                                <tr key={item._id} className="border-b hover:bg-gray-50">
                                    <td className="px-6 py-4 text-sm">
                                        <div className='flex items-center gap-6'>
                                            <img
                                                src={item.profileImageUrl || profile}
                                                alt={item.name}
                                                className='w-10 h-10 rounded-full object-cover'
                                            />
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        <div className="flex flex-col">
                                            <span className="font-medium">{item.name}</span>
                                            <span className="text-gray-500 text-xs">{item.email}</span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        {item.userCv && item.userCv[0] ? (
                                            <a
                                                href={item.userCv[0]}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                View Portfolio
                                            </a>
                                        ) : 'N/A'}
                                    </td>
                                    <td className="px-6 py-4 text-sm">{formatDate(item.createdAt)}</td>
                                    <td className="px-6 py-4 text-sm">
                                        {getStatusBadge(item.status)}
                                    </td>
                                    <td className="px-6 py-4 relative">
                                        <button
                                            className="text-gray-400 hover:text-gray-600"
                                            onClick={() => toggleDropdown(index)}
                                        >
                                            <PiDotsThreeCircleVerticalLight className="h-5 w-5" />
                                        </button>
                                        {dropdownOpen === index && (
                                            <div
                                                ref={dropdownRef}
                                                className="absolute top-1/2 right-10 mt-2 w-48 bg-white border rounded-md shadow-lg z-50"
                                            >
                                                <ul className="py-1">
                                                    <li
                                                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                                                        onClick={() => {
                                                            setSelectedDesigner(item);
                                                            setShowDetails(true);
                                                            toggleDropdown(null);
                                                        }}
                                                    >
                                                        <Info className="h-4 w-4" />
                                                        View Details
                                                    </li>
                                                    {item.status === 1 && (
                                                        <>
                                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-green-600">
                                                                Approve
                                                            </li>
                                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-600">
                                                                Reject
                                                            </li>
                                                        </>
                                                    )}
                                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-600">
                                                        <button onClick={() => setIsDelete(true)}>
                                                            Delete
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
             
           

            {/* Pagination */}
            <div className="flex justify-end gap-10 items-center p-4 bg-white border-t">
                <button
                    className={`text-sm cursor-pointer ${currentPage === 1 ? 'text-gray-300' : 'text-gray-500 hover:text-gray-700'}`}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    Prev
                </button>
                <div className="flex gap-1">
                    {[...Array(totalPages)].map((_, i) => (
                        <button
                            key={i}
                            className={`px-3 py-1 text-sm rounded-md ${currentPage === i + 1
                                ? 'bg-[#84315E] text-white'
                                : 'text-gray-500 hover:bg-gray-100'
                                }`}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
                <button
                    className={`text-sm cursor-pointer ${currentPage === totalPages ? 'text-gray-300' : 'text-gray-500 hover:text-gray-700'
                        }`}
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    Next
                </button>
            </div>

            {/* Delete Modal */}
            <DeleteConfirmationModal
                isOpen={isDelete}
                onClose={() => setIsDelete(false)}
                itemName="designer"
            />
            <DesignerDetails
                isOpen={showDetails}
                onClose={() => {
                    setShowDetails(false);
                    setSelectedDesigner(null);
                }}
                designer={selectedDesigner}
            />
        </div>
    );
};

export default VerificationTable;