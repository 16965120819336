import { ChevronDown, Edit, Trash2 } from 'lucide-react'
import React, { useState } from 'react'

const KnowladgeBase = ({ data,handleFaqAction }) => {
    const [visibleFaq, setVisibleFaq] = useState(null);

    const toggleFaqVisibility = (id) => {
        setVisibleFaq((prev) => (prev === id ? null : id));
    };
    return (
        <div>
            <div className="bg-gray-50 p-6">
                <div className=" bg-white rounded-lg shadow p-4">
                    <h2 className="text-2xl font-semibold pb-5">All Faqs</h2>

                    {data && data.length > 0 ? (
                        data.map((faq) => (
                            <div
                                key={faq._id}
                            >
                                <div className=" border-b">
                                    <div className="flex items-center justify-between capitalize space-y-2">
                                        <div className="flex items-center space-x-2">
                                            <h3 className=" text-black text-lg ">{faq.title}</h3>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            {visibleFaq === faq._id && (
                                                <>

                                                    <button className="p-1 bg-red-500/10 rounded-md flex justify-start place-items-center gap-3 text-red-500 px-2 py-1 text-sm"  onClick={()=>handleFaqAction('REMOVE',faq)}>
                                                        <Trash2 className="h-4 w-4 text-red-600" /> Remove
                                                    </button>
                                                    <button className="p-1 bg-green-500 rounded-md flex justify-start place-items-center gap-2 text-white px-4 py-1 text-sm" onClick={()=>handleFaqAction('EDIT',faq)}>
                                                        <Edit className="h-4 w-4 text-white" /> Edit
                                                    </button>
                                                </>
                                            )}
                                            <button
                                                onClick={() => toggleFaqVisibility(faq._id)}
                                                className="p-1 hover:bg-gray-100 rounded-md"
                                            >
                                                <ChevronDown
                                                    className={`h-4 w-4 text-gray-600 transform ${visibleFaq === faq._id ? 'rotate-180' : ''
                                                        }`}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    {visibleFaq === faq._id && (
                                        <p className=" pb-2 text-sm text-gray-600">{faq.desc}</p>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No Data Found</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default KnowladgeBase
