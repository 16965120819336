import React from 'react';



const ButtonToggle = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className="gap-1 px-4">
      <div className=" w-full rounded-3xl flex">
        {tabs.map((tab) => (
          <button
            key={tab.value}
            className={`px-6 py-3 ${activeTab === tab.value ? 'text-gray-100 bg-[#84315E] rounded-3xl border-purple-600 font-medium' : 'text-gray-500'
              }`}
            onClick={() => onTabChange(tab.value)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ButtonToggle;
