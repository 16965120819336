// import {


import { DASHBOARD_COUNT_FAIL, DASHBOARD_COUNT_REQUEST, DASHBOARD_COUNT_SUCCESS } from "../_constants/dashboard.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";

  
  export const getDashboardCount = () => async (dispatch) => {
    try {
      dispatch({ type: DASHBOARD_COUNT_REQUEST });
  
      const response = await APIcallFunction({
        method: 'GET',
        endPoint: '/admin/dashboardCount',
        header: headerForPrivateAPI()
      });
  
      dispatch({
        type: DASHBOARD_COUNT_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_COUNT_FAIL,
        payload: error.message
      });
    }
  };