import React, { useState, useMemo } from 'react';
// import CommunityModerationTable from './CommunityModerationTable';
import AdvertismentTable from './AdvertisementTable';

const AdvertismentContainer = () => {
  const initialData = [
    { 
      id: 1,
      image: "/api/placeholder/40/40",
      title: "T-shirt",
      totalComments: 4,
      reportDate: "10/12/2002",
      status: "Not yet",
      createdDate: "20/12/2024"
    },
    { 
      id: 2,
      image: "/api/placeholder/40/40",
      title: "T-shirt",
      totalComments: 4,
      reportDate: "10/12/2002",
      status: "3",
      createdDate: "20/12/2024"
    },
    { 
      id: 3,
      image: "/api/placeholder/40/40",
      title: "T-shirt",
      totalComments: 4,
      reportDate: "10/12/2002",
      status: "Not yet",
      createdDate: "20/12/2024"
    },
    { 
      id: 4,
      image: "/api/placeholder/40/40",
      title: "T-shirt",
      totalComments: 4,
      reportDate: "10/12/2002",
      status: "3",
      createdDate: "20/12/2024"
    },
    { 
      id: 5,
      image: "/api/placeholder/40/40",
      title: "T-shirt",
      totalComments: 4,
      reportDate: "10/12/2002",
      status: "Not yet",
      createdDate: "20/12/2024"
    },
    { 
      id: 6,
      image: "/api/placeholder/40/40",
      title: "T-shirt",
      totalComments: 4,
      reportDate: "10/12/2002",
      status: "3",
      createdDate: "20/12/2024"
    },
    { 
      id: 7,
      image: "/api/placeholder/40/40",
      title: "T-shirt",
      totalComments: 4,
      reportDate: "10/12/2002",
      status: "Not yet",
      createdDate: "20/12/2024"
    }
  ];

  const [data, setData] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('all');
  const itemsPerPage = 7;

  const filteredData = useMemo(() => {
    return data.filter(item => 
      (statusFilter === 'all' || item.status === statusFilter) &&
      Object.values(item).some(value => 
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm, statusFilter]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="w-full bg-gray-50 min-h-screen">
      <AdvertismentTable data={initialData} />
    </div>
  );
};

export default AdvertismentContainer;