export const supportAndQuiriesConstant = {
    GET_TICKET_LIST_REQUEST: "GET_TICKET_LIST_REQUEST",
    GET_TICKET_LIST_SUCCESS: "GET_TICKET_LIST_SUCCESS",
    GET_TICKET_LIST_FAILURE: "GET_TICKET_LIST_FAILURE",

    GET_FAQ_LIST_REQUEST: "GET_FAQ_LIST_REQUEST",
    GET_FAQ_LIST_SUCCESS: "GET_FAQ_LIST_SUCCESS",
    GET_FAQ_LIST_FAILURE: "GET_FAQ_LIST_FAILURE",


    REPLY_TICKET_MSG_REQUEST: "REPLY_TICKET_MSG_REQUEST",
    REPLY_TICKET_MSG_SUCCESS: "REPLY_TICKET_MSG_SUCCESS",
    REPLY_TICKET_MSG_FAILURE: "REPLY_TICKET_MSG_FAILURE",


    TICKET_STATUS_UPDATE_REQUEST: "TICKET_STATUS_UPDATE_REQUEST",
    TICKET_STATUS_UPDATE_SUCCESS: "TICKET_STATUS_UPDATE_SUCCESS",
    TICKET_STATUS_UPDATE_FAILURE: "TICKET_STATUS_UPDATE_FAILURE",


    CREATE_FAQ_LIST_REQUEST: "CREATE_FAQ_LIST_REQUEST",
    CREATE_FAQ_LIST_SUCCESS: "CREATE_FAQ_LIST_SUCCESS",
    CREATE_FAQ_LIST_FAILURE: "CREATE_FAQ_LIST_FAILURE",

    EDIT_FAQ_LIST_REQUEST: "EDIT_FAQ_LIST_REQUEST",
    EDIT_FAQ_LIST_SUCCESS: "EDIT_FAQ_LIST_SUCCESS",
    EDIT_FAQ_LIST_FAILURE: "EDIT_FAQ_LIST_FAILURE",


    DELETE_FAQ_LIST_REQUEST: "DELETE_FAQ_LIST_REQUEST",
    DELETE_FAQ_LIST_SUCCESS: "DELETE_FAQ_LIST_SUCCESS",
    DELETE_FAQ_LIST_FAILURE: "DELETE_FAQ_LIST_FAILURE",


};
