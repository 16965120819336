import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import profile from '../../public/images/profile.png'

// Custom Dialog Components
const Dialog = ({ open, onOpenChange, children }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={() => onOpenChange(false)}
      />
      <div className="relative z-50">{children}</div>
    </div>
  );
};

const DialogContent = ({ children, className = "" }) => (
  <div className={`bg-white rounded-lg shadow-xl relative ${className}`}>
    {children}
  </div>
);

const DialogHeader = ({ children }) => (
  <div className="px-6 py-4 border-b border-gray-200">{children}</div>
);

// Custom Card Components
const Card = ({ children, className = "" }) => (
  <div className={`bg-white rounded-lg shadow-sm border border-gray-200 ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="px-6 py-4 border-b border-gray-200">{children}</div>
);

const CardContent = ({ children }) => (
  <div className="px-6 py-4">{children}</div>
);

const DesignerDetails = ({ isOpen, onClose, designer }) => {
  if (!designer) return null;

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusBadge = (status) => {
    const statusMap = {
      1: { text: 'Pending', class: 'bg-yellow-100 text-yellow-800' },
      2: { text: 'Approved', class: 'bg-green-100 text-green-800' },
      3: { text: 'Rejected', class: 'bg-red-100 text-red-800' }
    };
    const statusInfo = statusMap[status] || { text: 'Unknown', class: 'bg-gray-100 text-gray-800' };
    
    return (
      <span className={`px-2 py-1 rounded-full text-xs ${statusInfo.class}`}>
        {statusInfo.text}
      </span>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Designer Details</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </DialogHeader>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
          {/* Basic Information */}
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Basic Information</h3>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-center space-x-4">
                  <img
                    src={designer.profileImageUrl || profile}
                    alt={designer.name}
                    className="w-24 h-24 rounded-full object-cover"
                  />
                  <div>
                    <h3 className="font-semibold text-lg">{designer.name}</h3>
                    <p className="text-gray-500">{designer.email}</p>
                    <p className="text-gray-500">{designer.phone}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Status</p>
                  <div className="mt-1">{getStatusBadge(designer.status)}</div>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Registration Date</p>
                  <p>{formatDate(designer.createdAt)}</p>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Bio and Description */}
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Bio & Description</h3>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <p className="text-sm font-medium text-gray-500">Bio</p>
                  <p className="mt-1">{designer.bio || 'No bio provided'}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Product Description</p>
                  <p className="mt-1">{designer.productDesc || 'No product description provided'}</p>
                </div>
                {designer.rejectReason && (
                  <div>
                    <p className="text-sm font-medium text-gray-500">Rejection Reason</p>
                    <p className="mt-1 text-red-600">{designer.rejectReason}</p>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>

          {/* Portfolio & Demo Products */}
          <Card className="md:col-span-2">
            <CardHeader>
              <h3 className="text-lg font-semibold">Portfolio & Demo Products</h3>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                {/* Portfolio/CV */}
                <div>
                  <h4 className="font-medium mb-2">Portfolio Documents</h4>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {designer.userCv && designer.userCv.map((url, index) => (
                      <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                      >
                        <img
                          src={url}
                          alt={`Portfolio ${index + 1}`}
                          className="w-full h-40 object-cover rounded-lg shadow-sm hover:opacity-75 transition-opacity"
                        />
                      </a>
                    ))}
                  </div>
                </div>

                {/* Demo Products */}
                <div>
                  <h4 className="font-medium mb-2">Demo Products</h4>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {designer.demoProductUrl && designer.demoProductUrl.map((url, index) => (
                      <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                      >
                        <img
                          src={url}
                          alt={`Demo Product ${index + 1}`}
                          className="w-full h-40 object-cover rounded-lg shadow-sm hover:opacity-75 transition-opacity"
                        />
                      </a>
                    ))}
                  </div>
                </div>

                {/* ID Documents */}
                <div>
                  <h4 className="font-medium mb-2">ID Documents</h4>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {designer.idUrl && designer.idUrl.map((url, index) => (
                      <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                      >
                        <img
                          src={url}
                          alt={`ID Document ${index + 1}`}
                          className="w-full h-40 object-cover rounded-lg shadow-sm hover:opacity-75 transition-opacity"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DesignerDetails;