import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchCommunity } from '../actions/community.actions';
// import CommunityModerationTable from './CommunityModerationTable';
// import { fetchCommunity } from '../../_actions/communityModeration.action';
import { fetchCommunityManage } from '../../_actions/communityManage.action';
import CommunityModerationTable from './CommunityTable';
import { deleteCommunity } from '../../_actions/communityManage.action';

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const CommunityModerationContainer = () => {
  const dispatch = useDispatch();
  const { loading, community, error } = useSelector((state) => state.communityM);
  console.log("Raw community state:", community);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('all');
  const { deleteLoading, deleteError } = useSelector((state) => state.communityM);
  const itemsPerPage = 7;

  useEffect(() => {
    dispatch(fetchCommunityManage());
  }, [dispatch]);

  const handleDelete = async (communityId) => {
    try {
      await dispatch(deleteCommunity(communityId));
      // You might want to show a success message here
    } catch (error) {
      // Handle error, maybe show an error message
      console.error('Failed to delete community:', error);
    }
  };

  console.log("DATA", community?.list)

  // Transform API data to match the table structure
  const transformedData = useMemo(() => {
    if (!community?.list) return [];

    return community.list.map((item, index) => ({
      id: index + 1,
      _id: item?._id,
      image: item.imgUrls[0] || "/api/placeholder/40/40",
      title: item.title,
      totalComments: item.desc,
      isDisable: item?.isDisable,
      status: item.reports > 0 ? "Reported" : "Not yet",
      createdDate: formatDate(item.createdAt),
      // Additional fields from API
      views: item.views,
      likes: item.like,
      shares: item.share,
      description: item.desc
    }));
  }, [community]);

  const filteredData = useMemo(() => {
    return transformedData.filter(item =>
      (statusFilter === 'all' || item.status === statusFilter) &&
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [transformedData, searchTerm, statusFilter]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return <div className="w-full h-screen flex items-center justify-center">Loading...</div>;
  }

  if (error) {
    return <div className="w-full h-screen flex items-center justify-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="w-full bg-gray-50 min-h-screen">
      <CommunityModerationTable
        data={currentData}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onSearchChange={setSearchTerm}
        searchTerm={searchTerm}
        onDelete={handleDelete}
        deleteLoading={deleteLoading}
      />
    </div>
  );
};

export default CommunityModerationContainer;