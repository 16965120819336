import React from "react";
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom';
import Layout from "./Layout/Layout";
// import NetworkDetector from '../components/Hoc/NetworkDetector';
import Error from "../pages/error/Error";
import Login from "../pages/login/Login";
import { APIcallFunction, headerForPublicAPI } from "../_helpers";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/app/*" element={<PrivateRoute component={Layout} />} />
      <Route path="/login" element={<PublicRoute component={Login} />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = window.sessionStorage.getItem('adminuser');
  
  // Check if user is authenticated and parse the data
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  try {
    const userData = JSON.parse(isAuthenticated);
    if (!userData || !userData.token) {
      return <Navigate to="/login" />;
    }
    return <Component {...rest} />;
  } catch (error) {
    console.error('Error parsing user data:', error);
    return <Navigate to="/login" />;
  }
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = window.sessionStorage.getItem('adminuser');
  
  // If user is already authenticated, redirect to dashboard
  if (isAuthenticated) {
    return <Navigate to="/app/dashboard" />;
  }
  
  return <Component {...rest} />;
};

// Modified login function to handle successful login
export const handleLogin = async (credentials) => {
  try {
    const response = await APIcallFunction({
      method: 'POST',
      endPoint: '/auth/login',
      body: credentials,
      header: headerForPublicAPI
    });
    
    if (response.data) {
      // Store user data in session storage
      window.sessionStorage.setItem('adminuser', JSON.stringify(response.data));
      
      // Redirect to dashboard
      window.location.replace('/app/dashboard');
    }
    return response;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};


export default App;