import { FETCH_COMMUNITY_FAILURE, FETCH_COMMUNITY_REQUEST, FETCH_COMMUNITY_SUCCESS } from "../_constants/community.constants";

const initialState = {
  loading: false,
  community: {
    data: {
      list: [],
      total: 0
    }
  },
  error: null
};

export default function communityReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMUNITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_COMMUNITY_SUCCESS:
      return {
        ...state,
        community: action.payload, // Changed from designers to community
        loading: false
      };
    case FETCH_COMMUNITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

