import moment from 'moment';
import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlineDotsVertical } from 'react-icons/hi';

const CustomerSupportTable = ({ data, showMenu, isMenu, clickedUserId, handleAction }) => {

   
    
    return (
        <div className=" h-screen">
            <table className="min-w-full table-auto border-collapse border border-[#E2E8F0]">
                <thead className="bg-gray-100">
                    <tr>
                        {['#', 'Ticket ID', 'User Name', 'Email ID', 'Subject', 'Status', 'Date & Time', 'Action'].map((header, index) => (
                            <th
                                key={index}
                                className="py-2 px-4 text-left text-sm  text-black font-semibold"
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 ? (
                        data.map((ele, index) => (
                            <tr key={index} className="border-t border-gray-200 text-sm capitalize text-[#475569]">
                                <td className="py-2 px-4 text-left">{index + 1}</td>
                                <td className="py-2 px-4 text-left">{ele?._id}</td>
                                <td className="py-2 px-4 text-left">{ele?.userId?.name}</td>
                                <td className="py-2 px-4 text-left">{ele?.userId?.email}</td>
                                <td className="py-2 px-4 text-left">{ele?.title}</td>
                                <td
                                    className={`py-2 px-4 text-left font-semibold `}
                                >
                                    <button className={` px-4 py-2 rounded-full ${ele?.status === 1
                                        ? 'bg-green-500/10 text-green-500'
                                        : ele?.status === 2
                                            ? 'bg-yellow-500/10 text-yellow-500'
                                            : 'bg-blue-500/10 text-blue-500'
                                        }`}>

                                        {ele?.status === 1 ? 'Open' : ele?.status === 2 ? 'Progress' : 'Resolve'}
                                    </button>
                                </td>
                                <td className="py-2 px-4 text-left">
                                    {moment(ele?.createdAt).format('YYYY-MM-DD hh:mm A')}
                                </td>


                                <td className="py-2 px-3 text-center relative">
                                    <span
                                        className="w-8 h-8 rounded-full  flex justify-center items-center border border-gray-300 cursor-pointer hover:bg-gray-100 transition-colors"
                                        onClick={() => showMenu(ele?._id)}
                                    >
                                        <HiOutlineDotsVertical className='text-md' />

                                    </span>

                                    <div className="absolute top-1/2 right-0 mt-2 w-40 bg-white z-50">
                                        {isMenu && clickedUserId === ele?._id && (
                                            <ul className="bg-[#FFFFFF] rounded-md  shadow space-y-2 text-left ">
                                                <li
                                                    className="px-4 py-1 border-b  text-gray-700 hover:bg-[#725EFE33] hover:text-[#715EFE] cursor-pointer transition-colors"
                                                    onClick={() => handleAction('VIEW', ele)}
                                                >
                                                    View Full History
                                                </li>
                                                {/* <li className='border-b p-2' onClick={() => handleAction('UPDATE', ele)}>Update Status</li> */}
                                                <li
                                                    className="px-4 py-1 border-b text-gray-700 hover:bg-[#725EFE33] hover:text-[#715EFE] cursor-pointer transition-colors"
                                                    onClick={() => handleAction('CLOSE', ele)}
                                                >
                                                    Clsoe Ticekt
                                                </li>


                                            </ul>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={8} className="py-4 px-4 text-center text-gray-500">
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default CustomerSupportTable;
