import React from 'react'
import card from '../../../public/images/card.svg'

const Card = ({Aamount, title}) => {
  return (
    <div>
        <div className='bg-white drop-shadow-lg shadow-[#857D7D40]/20 p-4 rounded-lg  py-6'>
            <div className='flex justify-between items-start p-1'>
                <div className='space-y-2'>
                    <p className='text-gray-500 text-[20px]'>{title}</p>
                    <h2 className='text-[35px] font-[600]'>{Aamount}</h2>
                </div>
              {/* right now it is the icon number one  */}
              <img src={card} alt='card'/>
            </div>
        </div>
    </div>
  )
}

export default Card