export const FETCH_COMMUNITY_REQUEST = 'FETCH_COMMUNITY_REQUEST';
export const FETCH_COMMUNITY_SUCCESS = 'FETCH_COMMUNITY_SUCCESS';
export const FETCH_COMMUNITY_FAILURE = 'FETCH_COMMUNITY_FAILURE';

export const FETCH_COMMUNITY_MANAGE_REQUEST = 'FETCH_COMMUNITY_MANAGE_REQUEST';
export const FETCH_COMMUNITY_MANAGE_SUCCESS = 'FETCH_COMMUNITY_MANAGE_SUCCESS';
export const FETCH_COMMUNITY_MANAGE_FAILURE = 'FETCH_COMMUNITY_MANAGE_FAILURE';

export const DELETE_COMMUNITY_REQUEST = 'DELETE_COMMUNITY_REQUEST';
export const DELETE_COMMUNITY_SUCCESS = 'DELETE_COMMUNITY_SUCCESS';
export const DELETE_COMMUNITY_FAILURE = 'DELETE_COMMUNITY_FAILURE';


export const STATUS_UPDATE_COMMUNITY_REQUEST = 'STATUS_UPDATE_COMMUNITY_REQUEST';
export const STATUS_UPDATE_COMMUNITY_SUCCESS = 'STATUS_UPDATE_COMMUNITY_SUCCESS';
export const STATUS_UPDATE_COMMUNITY_FAILURE = 'STATUS_UPDATE_COMMUNITY_FAILURE';
