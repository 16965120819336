import React, { useState } from 'react';
import { X } from 'lucide-react';

const ViewFormPopup = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    image: null,
    productName: '',
    designerName: '',
    price: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={onClose}>
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative z-50" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        
        <h2 className="text-2xl font-bold mb-6">Add New Product</h2>
        
        <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block text-sm mb-2">Order ID</label>
          <input
            type="text"
            name="orderId"
            value={formData.orderId}
            onChange={handleChange}
            placeholder="Enter Order ID"
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-6">
          <h3 className="text-lg mb-4">Customer Information</h3>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm mb-2">Contact Info</label>
              <input
                type="text"
                name="contactInfo"
                value={formData.contactInfo}
                onChange={handleChange}
                placeholder="Contact Info"
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm mb-2">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Address"
              className="w-full p-2 border rounded"
            />
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-lg mb-4">Product Order</h3>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm mb-2">Product Name</label>
              <input
                type="text"
                name="productName"
                value={formData.productName}
                onChange={handleChange}
                placeholder="Product Name"
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm mb-2">Quantity</label>
              <input
                type="text"
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
                placeholder="Quantity"
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-2">Price</label>
            <input
              type="text"
              name="price"
              value={formData.price}
              onChange={handleChange}
              placeholder="Price"
              className="w-full p-2 border rounded"
            />
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-lg mb-4">Payment Method</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm mb-2">Payment Method</label>
              <input
                type="text"
                name="paymentMethod"
                value={formData.paymentMethod}
                onChange={handleChange}
                placeholder="Payment Method"
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm mb-2">Total Amount Paid</label>
              <input
                type="text"
                name="totalAmountPaid"
                value={formData.totalAmountPaid}
                onChange={handleChange}
                placeholder="Total Amount Paid"
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            type="submit"
            className="bg-[#84315E] text-white px-6 py-2 rounded-3xl hover:bg-opacity-90"
          >
            Update Order Status
          </button>
          <button
            type="button"
            className="px-6 py-2 border rounded hover:bg-gray-50"
          >
            Cancel
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default ViewFormPopup;