import React from "react";

const TextAreaField = ({label,name,value,onChange,placeholder,rows = 4,
}) => {
  return (
    <div className="w-full mb-4">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
      </label>
      <textarea
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        className="w-full px-3 py-2 border bg-[#F1F4F9] border-[#D8D8D8] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      ></textarea>
    </div>
  );
};

export default TextAreaField;