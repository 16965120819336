import React from 'react';
import { Trash2, X } from 'lucide-react';

const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, itemName = 'product list' }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50" onClick={onClose}>
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative" onClick={(e) => e.stopPropagation()}>
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X size={20} />
        </button>

        <div className="text-center">
          {/* Title */}
          <h2 className="text-xl font-semibold mb-2">Delete Modal</h2>

          {/* Icon */}
          <div className="flex justify-center mb-4">
            <div className="bg-red-100 p-4 rounded-full">
              <Trash2 className="text-red-500 w-6 h-6" />
            </div>
          </div>

          {/* Confirmation message */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Are you sure?</h3>
            <p className="text-gray-600">
              Are you want to delete {itemName} delete
            </p>
          </div>

          {/* Action buttons */}
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 text-gray-700"
            >
              Cancel
            </button>
            <button
              onClick={onDelete}
              className="px-4 py-2 rounded-md bg-[#84315E] hover:bg-purple-800 text-white"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirmationModal;