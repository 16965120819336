import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonToggle from '../../components/ui/toggleButton/ToggleButton';
import Input from '../../components/ui/Input/Input';
import Button from '../../components/ui/Button/Button';
import { GoPlus } from "react-icons/go";
import Select from '../../components/ui/Select/Select';
import CusstomerSupportTable from './Table/CusstomerSupportTable';
import KnowladgeBase from './Table/KnowladgeBase';
import { supportAction } from '../../_actions/supportAndQuires.action';
import ViewModal from './Modal/ViewModal';
import AddModal from './Modal/AddModal';
import EditModal from './Modal/EditModal';
import DeleteConfirmationModal from '../../components/ui/DeleteModal';


const SupportManagement = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state)
  const { support } = selector ? selector : {}
  const { ticketList, faqList } = support ? support : {}
  const { list } = ticketList ? ticketList : {}
  const [isUpdateModal, setIsUpdateModal] = useState(false)

  const [keyword, setKeyword] = useState('')


  console.log('keywordkeyword', keyword)

  const [activeTab, setActiveTab] = useState('Shopper');
  const [isMenu, setIsMenu] = useState(false)
  const [clickedUserId, setClickedUserId] = useState('')
  const [clickedRowData, setIsClickedRowData] = useState()

  console.log('listlistlistlistlist', clickedRowData)
  /// View State=====>>>>>>
  const [isViewModal, setIsViewModal] = useState(false)

  //// Faq state========>>>>>
  const [isFaqCreateModal, setIsFaqCreateModal] = useState(false)
  const [faqFeilds, setFaqFeilds] = useState({ title: "", desc: "" })
  const [isFaqEditModal, setIsEditFaqModal] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)



  const showMenu = (userId) => {
    if (clickedUserId === userId) {
      setIsMenu(!isMenu);
    } else {
      setClickedUserId(userId);
      setIsMenu(true);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    { label: 'Customer Support Tickets', value: 'Shopper' },
    { label: 'Knowledge Base', value: 'Designer' },
  ];

  const options = [{ value: 1, label: "Active" }]

  useEffect(() => {
    let apiPayloadData = {
      keyword: keyword
    }
    dispatch(supportAction.getTicketList(apiPayloadData));
    dispatch(supportAction.getFaqList())
  }, [keyword]);


  const handleAction = (action, data) => {
    setIsClickedRowData({ ...data, comments: data.comments || "", })
    switch (action) {
      case "VIEW":
        setIsViewModal(prevState => !prevState)
        break;
      case "UPDATE":
        setIsUpdateModal(prevState => !prevState);
        dispatch(supportAction.ticketStatusUpdate({ ticketId: data?._id, status: 1 }))
        break;
      case "CLOSE":
        dispatch(supportAction.ticketStatusUpdate({ ticketId: data?._id, status: 3 }))
        setIsMenu(false)
        break;
      default:
        console.log("No Action")
    }
  }

  const handleClose = (action) => {
    if (action === "VIEW") {
      setIsViewModal(prevState => !prevState)
      setIsMenu(false)
    } else if (action === "Create") {
      setIsFaqCreateModal(prevState => !prevState)
      setFaqFeilds({})
    } else if (action === "EDIT") {
      setIsEditFaqModal(prevState => !prevState)
    } else if (action === 'DELETE') {
      setIsDeleteModal(prevState => !prevState)
    }
  }

  const handleViewOnChange = (e) => {
    const { name, value } = e.target;
    setIsClickedRowData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleReplySubmit = (e) => {
    e.preventDefault()
    let credForPayload = {
      ticketId: clickedRowData?._id,
      message: clickedRowData?.comments
    }
    dispatch(supportAction.replyMessageOnTicket(credForPayload))
    setIsClickedRowData({})
    setIsViewModal(prevState => !prevState)
    setIsMenu(false)

  }

  const handleAddFaq = () => {
    setIsFaqCreateModal(prevState => !prevState)
  }
  const handleCreateFaqonChnage = (e) => {
    const { name, value } = e.target;
    setFaqFeilds({ ...faqFeilds, [name]: value })
  }
  const handleEditFaqonChnage = (e) => {
    const { name, value } = e.target;
    setIsClickedRowData({ ...clickedRowData, [name]: value })
  }


  const handleCreateOnSubmit = (e) => {
    e.preventDefault()
    let apiPayload = {
      "title": faqFeilds?.title,
      "desc": faqFeilds?.desc
    }
    dispatch(supportAction.createFaq(apiPayload))
    setIsFaqCreateModal(false)
    setFaqFeilds({})
  }

  const handleFaqAction = (action, data) => {
    setIsClickedRowData(data)
    switch (action) {
      case "EDIT":
        setIsEditFaqModal(prevState => !prevState)
        break;
      case "REMOVE":
        setIsDeleteModal(prevState => !prevState)
        break;
      default:
        console.log("No Action")
    }
  }

  const handleEditSubmit = () => {
    let payLoad = {
      faqId: clickedRowData?._id,
      title: clickedRowData?.title,
      desc: clickedRowData?.desc
    }
    dispatch(supportAction.editFaq(payLoad))
    setIsEditFaqModal(prevState => !prevState)

  }

  const handleDeleteSubmit = (e) => {
    e.preventDefault()
    console.log('clicked')
    let deletePayload = {
      faqId: clickedRowData?._id
    }
    dispatch(supportAction.deleteFaq(deletePayload))
    setIsDeleteModal(prevState => !prevState)
  }


  return (
    <div className="w-full bg-gray-50 min-h-screen p-4">
      <div className='flex justify-between place-items-center w-full'>
        <div className="border rounded-3xl">
          <ButtonToggle tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
        </div>
        <div className='flex justify-start place-items-center gap-2'>

          <Input placeholder={`Search In Product`} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
          {
            activeTab === 'Shopper' && (
              <>
                <Select options={options} />
              </>
            )
          }
          {
            activeTab === 'Designer' && (
              <>
                <Button label={`Add Faq`} leftIcon={<GoPlus className='text-lg text-white' />}
                  className='bg-[#84315E] border-[#84315E] items-center gap-2 border px-4 py-2 rounded-full text-white' onClick={handleAddFaq} />
              </>
            )
          }
        </div>
      </div>
      <section className='mt-3'>
        {
          activeTab === 'Shopper' ? <CusstomerSupportTable data={list}
            showMenu={showMenu} isMenu={isMenu} clickedUserId={clickedUserId} handleAction={handleAction}
          /> : <KnowladgeBase data={faqList} handleFaqAction={handleFaqAction} />
        }
      </section>
      {
        isViewModal && (<ViewModal onCancel={() => handleClose('VIEW')} title={`Mail Support`} inputValue={clickedRowData} handleOnChange={handleViewOnChange} onSubmit={handleReplySubmit} />)
      }
      {
        isFaqCreateModal && (<AddModal title="Add Faq" inputValue={faqFeilds} handleOnChange={handleCreateFaqonChnage} onSubmit={handleCreateOnSubmit} onCancel={() => handleClose('Create')} />)
      }
      {
        isFaqEditModal && (<EditModal title={`Edit Faq`} inputValue={clickedRowData} handleOnChange={handleEditFaqonChnage} onSubmit={handleEditSubmit} onCancel={() => handleClose('EDIT')} />)
      }
      {
        isDeleteModal && (<DeleteConfirmationModal isOpen={isDeleteModal} onDelete={handleDeleteSubmit} onClose={() => handleClose('DELETE')} />)
      }
    </div>
  );
};

export default SupportManagement;