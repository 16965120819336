import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from 'lucide-react';
import CommisionTable from './CommisionTable';
// import { fetchProducts } from '../_actions/commision.actions';
import { fetchCommision } from '../../_actions/commision.action';

const CommisionTableContainer = () => {
  const dispatch = useDispatch();
  const { loading, commision = [], error, total } = useSelector(state => state.commision);

  useEffect(() => {
    dispatch(fetchCommision());
  }, [dispatch]);

  const transformedData = commision.map(item => ({
    id: item._id,
    designer: item.userId?.name || 'N/A',
    designerId: item?.userId?._id,
    currentRate: `${item.currentRateInPer}`,
    customRate: `${item.customRateInPer}`,
    createdAt: new Date(item.createdAt).toLocaleDateString()
  }));

  if (loading) {
    return <div className="w-full h-screen flex items-center justify-center">Loading...</div>;
  }

  if (error) {
    return <div className="w-full h-screen flex items-center justify-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="w-full">
      <CommisionTable data={transformedData} />
    </div>
  );
};

export default CommisionTableContainer;