import { FETCH_COMMISION_FAILURE, FETCH_COMMISION_REQUEST, FETCH_COMMISION_SUCCESS } from "../_constants/commision.constants";
// import { FETCH_PRODUCTS_FAILURE, FETCH_PRODUCTS_REQUEST, FETCH_PRODUCTS_SUCCESS } from "../_constants/product.constants";

const initialState = {
    loading: false,
    commision: [],
    error: null,
    total: 0
};

export default function commisionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_COMMISION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_COMMISION_SUCCESS:
            return {
                loading: false,
                // Change this line to access data.list instead of just list
                commision: action.payload.data.list,
                // Change this line to access data.total
                total: action.payload.data.total,
                error: null
            };
        case FETCH_COMMISION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}